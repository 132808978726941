<template>
  <header ref="headerRef" class="header" :class="{ active: activeScroll }">
    <div class="header__wrapper">
      <AdditionalInfo
        class="header__additional"
        @get-height-additional="getHeightAdditional"
        :content="dataHeaderBanner[0]"
      />
      <MainInfo
        class="header__main-info"
        :height-header="heightHeader"
        @get-height-main-info="getHeightMainInfo"
      />
      <RenderCacheable :max-age="86400" :cache-key="getUserLanguage.name">
        <ModalMenu />
      </RenderCacheable>
    </div>
  </header>
  <div
    v-if="headerCatalogState.active"
    class="catalog-blur"
    @click="hiddenCatalog"
  />
</template>

<script setup>
import { useHeaderStore } from "~~/store/headerStore";
import AdditionalInfo from "~~/modules/header/sections/AdditionalInfo.vue";
import MainInfo from "~~/modules/header/sections/MainInfo.vue";
import ModalMenu from "~~/modules/header/sections/ModalMenu.vue";
import { addScopedEventListener } from "~~/utils/eventListener";
import { useLanguageFilterStore } from "~/store/languageFilterStore";
import { useContentfulStore } from "~/store/contentfulStore";

const contentful = useContentfulStore();
await contentful.fetchDataHeaderBanner();

const dataHeaderBanner = contentful.getDataHeaderBanner;

const headerStore = useHeaderStore();
const headerCatalogState = headerStore.getModalCatalog;
const setHeaderHeight = headerStore.setHeightHeader;
const setAdditionalInfo = headerStore.setAdditionalInfo;
const activeCatalog = headerStore.activeCatalog;

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const heightAdditionalInfo = ref(0);
const heightHeader = ref(0);
const heightMainInfo = ref(0);
const activeScroll = ref(false);

const headerRef = ref(null);

function getHeightAdditional(height) {
  heightAdditionalInfo.value = height;
  setAdditionalInfo(`${height}px`);
}

function getHeightHeader() {
  heightHeader.value = headerRef.value?.offsetHeight;

  setHeaderHeight(heightMainInfo.value);
}

function getHeightMainInfo(height) {
  heightMainInfo.value = `${height}px`;
}

function stateHeader() {
  activeScroll.value = window.scrollY >= heightAdditionalInfo.value;
}

function hiddenCatalog() {
  activeCatalog(false);
  document.body.style.overflow = "auto";
}

onMounted(() => {
  getHeightHeader();
  stateHeader();
  addScopedEventListener(window, "resize", getHeightHeader);
  addScopedEventListener(window, "scroll", getHeightHeader);
  addScopedEventListener(window, "scroll", stateHeader);
});
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  z-index: 501;

  background-color: white;

  &.active {
    margin-bottom: v-bind(heightMainInfo);

    .header__additional {
      opacity: 0;
      visibility: hidden;
    }

    .header__main-info {
      position: fixed;
      top: 0;

      z-index: 501;
    }
  }

  &__main-info {
    width: 100%;

    padding: 4px 16px;

    @include bigMobile {
      z-index: 501;

      padding: 8px 16px;
    }
  }

  &__additional {
    transition: opacity 0.5s ease-in-out;
  }
}

.catalog-blur {
  display: flex;

  position: fixed;
  @include setAbs(0, 0, 0, 0);
  z-index: 500;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}
</style>
